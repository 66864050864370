import { HELP_CENTER_LINK } from "src/Constants";

export const MENU_LIST = [
    {
        title: "Home",
        path: "/",
    },
    { title: "Features", path: "/feature" },
    { title: "Merchant", path: "/merchant" },
    { title: "Help Center", path: HELP_CENTER_LINK },
    {
        title: "Resources",
        children: [
          {
            title: "Merchant Preview",
            path: "https://shopeepay.ph/merchant-demo",
          },
          {
            title: " Integration Guide",
            path: "https://product.shopeepay.com/",
          },
        ],
    },
]

export const EXTRA_BUTTON = {
    title: "Download",
    path: "/download",
};